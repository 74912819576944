<!-- eslint-disable vue/max-attributes-per-line -->
<template>
	<div class="third-step-wrapper">
		<h2>Choose a tentative date for the website to be completed</h2>
		<h3>(skip if unsure)</h3>
		<div class="third-step-datepicker-wrapper">
			<input
				v-model="store.quoteDatePickerVal"
				type="date"
				:min="currentDate"
				:max="maxDate"
			/>
		</div>
		<div class="btn-wrapper">
			<router-link class="btn" :to="{ name: 'QuoteFeatures' }"
				>Back</router-link
			>
			<router-link class="btn btn-next" :to="{ name: 'QuoteDesign' }"
				>Next</router-link
			>
		</div>
	</div>
</template>

<script setup lang="ts">
import animateElementsOnScroll from "@/js/intersection-observer.v2.js";
import { DateInstance } from "@/assets/js/dateHelper";
import { useDefaultStore } from "@/store";
import dayjs from "dayjs";

const currentDate = DateInstance.getFormattedDate("", "YYYY-MM-DD", false);
const maxDate = DateInstance.getFormattedDate(
	dayjs(currentDate, "YYYY-MM-DD").add(10, "year").format(),
	"YYYY-MM-DD",
	false,
);
const store = useDefaultStore();

onMounted(() => {
	animateElementsOnScroll(
		".third-step-wrapper",
		"slideFromBot 1s 0s forwards",
		" h2 , h3, .third-step-datepicker-wrapper, .btn-wrapper",
	);
	if (store.quoteDatePickerVal === "") store.quoteDatePickerVal = currentDate;
});
</script>

<style lang="scss" scoped>
.third-step-wrapper {
	width: 100%;
	max-width: 1500px;
	margin: 0 auto;

	h2 {
		font-style: normal;
		font-weight: 600;
		max-width: 800px;
		margin: 0 auto;
		text-align: center;
		font-size: 32px;
		line-height: 40px;
	}

	h3 {
		text-align: center;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		max-width: 800px;
		margin: 0 auto 48px;
		color: $second-app-color;
	}

	.third-step-datepicker-wrapper {
		height: 44px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		border: 1px solid;
		border-color: $section-color;
		box-sizing: border-box;
		border-radius: 11px;
		width: 100%;
		max-width: 500px;
		margin: 0 auto;
		padding-right: 18px;
		position: relative;

		input {
			flex-grow: 1;
			color: var(--vt-c-white);
			background-color: transparent;
			padding-right: 0;
			min-width: 0;
			z-index: 2;
			height: 100%;

			&::placeholder {
				color: var(--vt-c-white);
				opacity: 1;
			}

			&:focus {
				background-color: transparent;
			}
		}

		input[type="date"]::-webkit-calendar-picker-indicator {
			cursor: pointer;
			opacity: 1;
			width: 25px;
			filter: brightness(0) saturate(100%) invert(49%) sepia(20%) saturate(872%)
				hue-rotate(171deg) brightness(91%) contrast(90%);
		}
	}

	.btn-wrapper {
		display: flex;
		justify-content: center;
		z-index: 2;
		.btn {
			margin: 48px 0;
			width: 200px;

			&.btn-next {
				background: $app-color;
				margin-left: 1rem;
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.third-step-wrapper {
		h2 {
			font-size: 54px;
			line-height: 58px;
		}

		h3 {
			font-size: 25px;
		}

		.btn-wrapper {
			.btn {
				&.btn-next {
					margin-left: 2rem;
				}
			}
		}
	}
}
</style>
