import { defineStore } from "pinia";

export const useDefaultStore = defineStore("main", () => {
	const quoteFeatures = ref<number[]>([]);
	const quoteDatePickerVal = ref("");
	const quoteSelectedDesign = ref("not_selected");
	const quoteName = ref("");
	const quoteLastName = ref("");
	const quoteEmail = ref("");
	const quotePhone = ref("");
	const quoteComment = ref("");
	const quoteReferrer = ref<string | null>(null);
	const cookieConsent = ref(false);
	const priceImpactFeatures = ref(0);
	const priceImpact = ref("low");
	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	function resetForm() {
		quoteFeatures.value = [];
		quoteDatePickerVal.value = "";
		quoteSelectedDesign.value = "not_selected";
		quoteName.value = "";
		quoteLastName.value = "";
		quoteEmail.value = "";
		quotePhone.value = "";
		quoteComment.value = "";
		priceImpact.value = "low";
	}

	const isFormValid = computed(() => {
		// const isNameValid = quoteName.value.trim() !== "";
		// const isLastNameValid = quoteLastName.value.trim() !== "";
		const isEmailValid = emailRegex.test(quoteEmail.value.trim());

		return /*isNameValid && isLastNameValid &&*/ isEmailValid;
	});

	function trackQuoteCTA() {
		// Track the CTA click
		fbq("trackCustom", "ClickGetQuote");
	}

	return {
		emailRegex,
		quoteFeatures,
		quoteDatePickerVal,
		quoteSelectedDesign,
		quoteName,
		quoteLastName,
		quoteEmail,
		quotePhone,
		quoteComment,
		resetForm,
		isFormValid,
		quoteReferrer,
		cookieConsent,
		priceImpact,
		priceImpactFeatures,
		trackQuoteCTA,
	};
});
