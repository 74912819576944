<!-- eslint-disable vue/max-attributes-per-line -->
<template>
	<div class="fourth-step-wrapper">
		<h2>Choose a design path for your website</h2>
		<h3 class="sub-text">(skip if unsure)</h3>
		<div class="fourth-step-items-container">
			<div
				class="item-wrapper left"
				:class="{
					selected:
						store.quoteSelectedDesign === 'template' ||
						hoveredOption === 'template',
				}"
				@click="selectOption('template')"
				@mouseover="hoverOption('template')"
				@mouseleave="hoverOption(null)"
			>
				<div class="imgwrap">
					<inline-svg :src="template" alt="icon" />
				</div>
				<h3 class="option-label">I already have something in mind</h3>
			</div>
			<div
				class="item-wrapper center"
				:class="{
					selected:
						store.quoteSelectedDesign === 'not_selected' ||
						hoveredOption === 'not_selected',
				}"
				@click="selectOption('not_selected')"
				@mouseover="hoverOption('not_selected')"
				@mouseleave="hoverOption(null)"
			>
				<div class="imgwrap">
					<inline-svg :src="ellipsis" alt="icon" />
				</div>
				<h3 class="option-label">I will need help deciding</h3>
			</div>
			<div
				class="item-wrapper"
				:class="{
					selected:
						store.quoteSelectedDesign === 'custom' ||
						hoveredOption === 'custom',
				}"
				@click="selectOption('custom')"
				@mouseover="hoverOption('custom')"
				@mouseleave="hoverOption(null)"
			>
				<div class="imgwrap">
					<inline-svg :src="figma" alt="icon" />
				</div>
				<h3 class="option-label">I want a custom design</h3>
			</div>
		</div>
		<div class="btn-wrapper">
			<router-link class="btn" :to="{ name: 'QuoteDate' }">Back</router-link>
			<router-link class="btn btn-next" :to="{ name: 'QuoteContact' }"
				>Next</router-link
			>
		</div>
	</div>
</template>

<script setup lang="ts">
import animateElementsOnScroll from "@/js/intersection-observer.v2.js";
import template from "@/assets/icons/quote/quote-view-comfy.svg";
import figma from "@/assets/icons/quote/quote-figma.svg";
import ellipsis from "@/assets/icons/quote/quote-ellipsis.svg";
import { useDefaultStore } from "@/store";

const store = useDefaultStore();
const hoveredOption = ref<string | null>(null);

const selectOption = (option: string) => {
	store.quoteSelectedDesign = option;
};

const hoverOption = (option: string | null) => {
	hoveredOption.value = option;
};

onMounted(() => {
	animateElementsOnScroll(
		".fourth-step-wrapper",
		"slideFromBot 1s 0s forwards",
		" h2, .sub-text, .fourth-step-items-container, .btn-wrapper",
	);
});
</script>

<style lang="scss" scoped>
.fourth-step-wrapper {
	max-width: 1400px;
	margin: 0 auto;

	h2 {
		font-style: normal;
		font-weight: 600;
		max-width: 800px;
		margin: 0 auto;
		text-align: center;
		font-size: 32px;
		line-height: 40px;
	}

	.sub-text {
		text-align: center;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		max-width: 800px;
		margin: 0 auto 48px;
		color: $second-app-color;
	}

	.fourth-step-items-container {
		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;

		.item-wrapper {
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			box-sizing: border-box;
			width: 350px;
			max-width: 98%;
			padding: 48px;
			margin-bottom: 48px;
			z-index: 2;
			cursor: pointer;
			border-top: 6px solid $second-app-color;
			border-right: 1px solid $second-app-color;
			border-left: 1px solid $second-app-color;
			border-bottom: 1px solid $second-app-color;
			border-radius: 10px;
			transition: opacity 0.3s ease-in-out;
			opacity: 0.4;

			.imgwrap {
				margin-bottom: 2rem;
				height: 50px;
				aspect-ratio: 1 / 1;
				text-align: center;

				:deep() {
					svg {
						height: 100%;
						path {
							fill: $second-app-color;
						}
					}
				}
			}

			.option-label {
				font-size: 20px;
				line-height: 24px;
				text-align: center;
				height: 48px;
			}

			&.selected {
				opacity: 1;
				box-shadow: 0 1px 3px 0 $second-app-color;
				.imgwrap {
					:deep() {
						svg {
							transform: scale(1.2);
						}
					}
				}
			}

			&:hover {
				.imgwrap {
					:deep() {
						svg {
							transform: scale(1.2);
							transition: transform 0.3s ease-in-out;
						}
					}
				}
			}
		}
	}

	.btn-wrapper {
		display: flex;
		justify-content: center;
		.btn {
			margin: 0 0 48px;
			width: 200px;
			z-index: 2;

			&.btn-next {
				background: $app-color;
				margin-left: 1rem;
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.fourth-step-wrapper {
		h2 {
			font-size: 54px;
			line-height: 58px;
		}

		.sub-text {
			font-size: 25px;
		}

		.fourth-step-items-container {
			flex-flow: row;
			flex-wrap: wrap;

			.item-wrapper {
				.option-label {
					font-size: 24px;
					line-height: 28px;
				}

				&.center {
					margin-right: 2rem;
					margin-left: 2rem;
				}
			}

			.glow-img {
				display: block;
			}
		}

		.btn-wrapper {
			.btn {
				&.btn-next {
					margin-left: 2rem;
				}
			}
		}
	}
}
</style>
