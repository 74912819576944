<!-- eslint-disable vue/max-attributes-per-line -->
<template>
	<div class="first-step-wrapper">
		<h2>Get your free quote today</h2>
		<h3>
			We know, you have an idea what you want to get, but you don't know
			<span>how much it will cost you</span>.
		</h3>
		<h3>
			Well, we got you. Give us some basic information about what you want in
			your project, and <span>get your free quote today</span>.
		</h3>
		<h3>
			If you don't know exactly what you need, just leave the fields empty and
			<span>we will help you figure it out</span> together.
		</h3>
		<router-link class="btn btn-next" :to="{ name: 'QuoteFeatures' }"
			>Start</router-link
		>
	</div>
</template>

<script setup lang="ts">
import animateElementsOnScroll from "@/js/intersection-observer.v2.js";

onMounted(() => {
	animateElementsOnScroll(
		".first-step-wrapper",
		"slideFromBot 1s 0s forwards",
		" h2 , h3, a",
	);
});
</script>

<style lang="scss" scoped>
.first-step-wrapper {
	max-width: 800px;
	margin: 0 auto;
	position: relative;

	h2 {
		text-align: center;
		font-size: 32px;
		line-height: 40px;
		font-style: normal;
		font-weight: 600;
		margin: 0 auto 48px;
	}

	h3 {
		text-align: left;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		max-width: 800px;
		margin: 0 auto 24px;

		span {
			color: $second-app-color;
			font-weight: bold;
		}
	}

	.btn {
		margin: 48px auto;
		width: 200px;
		background: $app-color;
		z-index: 2;
	}
}

@media screen and (min-width: 768px) {
	.first-step-wrapper {
		h2 {
			font-size: 54px;
			line-height: 48px;
			text-align: center;
		}

		h3 {
			font-size: 25px;
		}
	}
}
</style>
