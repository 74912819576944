<!-- eslint-disable vue/max-attributes-per-line -->
<template>
	<div class="fifth-step-wrapper">
		<h2>Tell us who we should contact with price information</h2>
		<div class="fifth-step-form-wrapper">
			<input v-model="store.quoteEmail" type="text" placeholder="Email*" />
			<input v-model="store.quoteName" type="text" placeholder="Name" />
			<input
				v-model="store.quoteLastName"
				type="text"
				placeholder="Last Name"
			/>
			<input v-model="store.quotePhone" type="text" placeholder="Phone" />
			<textarea
				v-model="store.quoteComment"
				placeholder="Feel free to tell us any custom details you have or ask any questions you would like"
			></textarea>
		</div>
		<!-- <div class="privacy-policy">
			By submitting, you are agreeing to our privacy policy, terms of use, and
			code of conduct.
		</div> -->
		<div class="btn-wrapper">
			<router-link class="btn" :to="{ name: 'QuoteDesign' }">Back</router-link>
			<div class="btn btn-next" @click.stop="handleSubmit">Submit</div>
			<div v-if="errorMsgBool" class="error-msg">{{ errorMessage }}</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import animateElementsOnScroll from "@/js/intersection-observer.v2.js";
import { useDefaultStore } from "@/store";

const store = useDefaultStore();
const router = useRouter();
const errorMessage = ref("");
const errorMsgBool = ref(false);
const errorMsgTimeout = ref<ReturnType<typeof setTimeout> | null>(null);
const userIP = ref<string | null>(null);
const countryCode = ref<string | null>(null);
const city = ref<string | null>(null);
const leadsRootApi = "https://435t123.mars2.mars-hosting.com/api/";

async function handleSubmit() {
	await getIp();

	// if (!store.quoteName || !store.quoteName.trim()) {
	// 	setErrorTimeout("Name is required.");
	// 	return;
	// }
	// if (!store.quoteLastName || !store.quoteLastName.trim()) {
	// 	setErrorTimeout("Last Name is required.");
	// 	return;
	// }
	if (!store.quoteEmail || !store.quoteEmail.trim()) {
		setErrorTimeout("Email is required.");
		return;
	}
	if (!store.emailRegex.test(store.quoteEmail)) {
		setErrorTimeout("Invalid email.");
		return;
	}
	const params = {
		name: store.quoteName,
		last_name: store.quoteLastName,
		email: store.quoteEmail,
		phone: store.quotePhone,
		requested_date: store.quoteDatePickerVal,
		design: store.quoteSelectedDesign,
		ftr_ids: JSON.stringify(store.quoteFeatures),
		comment: store.quoteComment,
		ip: userIP.value,
		city: city.value,
		country_code: countryCode.value,
		referrer: store.quoteReferrer,
	};
	try {
		const res = await axios.post(`${leadsRootApi}leads/leads-add`, params);
		console.warn(res.data.message);
		await router.push({ name: "QuoteDone" });
		store.resetForm();
	} catch (e: any) {
		setErrorTimeout("Something went wrong.");
		console.error(e);
	}
}

async function getIp() {
	try {
		const res = await axios.get(`${leadsRootApi}users/get-ip`);
		userIP.value = res.data.ip;
		if (userIP.value) getLoaction(userIP.value);
	} catch (e: any) {
		console.error(e);
	}
}

async function getLoaction(ip: string) {
	const accessToken = "94c8294a2a11db";
	try {
		const response = await axios.get(
			`https://ipinfo.io/${ip}?token=${accessToken}`,
		);
		city.value = response.data.city;
		countryCode.value = response.data.country;
	} catch (error) {
		console.error(error);
	}
}

function setErrorTimeout(msg: string) {
	errorMessage.value = msg;
	errorMsgBool.value = true;
	errorMsgTimeout.value && clearTimeout(errorMsgTimeout.value);
	errorMsgTimeout.value = setTimeout(() => {
		errorMsgBool.value = false;
	}, 5000);
}

onMounted(async () => {
	getIp();
	animateElementsOnScroll(
		".fifth-step-wrapper",
		"slideFromBot 1s 0s forwards",
		" h2 , h3, .fifth-step-form-wrapper, .privacy-policy, .btn-wrapper",
	);
});
</script>

<style lang="scss" scoped>
.fifth-step-wrapper {
	width: 100%;
	max-width: 1500px;
	margin: 0 auto;

	h2 {
		font-style: normal;
		font-weight: 600;
		max-width: 800px;
		margin: 0 auto 48px;
		text-align: center;
		font-size: 32px;
		line-height: 40px;
	}

	.fifth-step-form-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		box-sizing: border-box;
		width: 100%;
		max-width: 500px;
		margin: 0 auto;
		position: relative;

		textarea {
			margin-bottom: 0 !important;
			height: 132px !important;
			resize: none;
			padding-left: 12px;
			padding-top: 12px;
			text-indent: 0;

			&::placeholder {
				font-family: Arial, sans-serif;
				line-height: 22px;
			}
		}

		input,
		textarea {
			border-radius: 11px;
			border: 1px solid;
			border-color: $section-color;
			width: 100%;
			flex-grow: 1;
			color: var(--vt-c-white);
			background-color: transparent;
			min-width: 0;
			z-index: 2;
			height: 44px;
			margin-bottom: 24px;

			&::placeholder {
				color: $text-placeholder;
				opacity: 1;
			}

			&:focus {
				background-color: transparent;
			}
		}
	}

	.privacy-policy {
		text-align: center;
		font-style: italic;
		font-weight: 400;
		max-width: 400px;
		margin: 24px auto 0;
		color: $text-placeholder;
		font-size: 12px;
	}

	.btn-wrapper {
		display: flex;
		justify-content: center;
		z-index: 2;
		position: relative;
		margin-bottom: 48px;
		.btn {
			margin: 26px 0 12px;
			width: 200px;

			&.btn-next {
				background: $app-color;
				margin-left: 1rem;
			}
		}

		.error-msg {
			position: absolute;
			top: 110%;
			left: 50%;
			transform: translateX(-50%);
			width: 100%;
			text-align: center;
			font-weight: 700;
			max-width: 400px;
			margin: 0 auto;
			color: rgb(207, 1, 1);
			font-size: 18px;
		}
	}
}

@media screen and (min-width: 768px) {
	.fifth-step-wrapper {
		h2 {
			font-size: 54px;
			line-height: 58px;
		}

		.privacy-policy {
			font-size: 14px;
		}

		.btn-wrapper {
			.btn {
				&.btn-next {
					margin-left: 2rem;
				}
			}
		}
	}
}
</style>
