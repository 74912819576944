<!-- eslint-disable vue/max-attributes-per-line -->
<template>
	<div class="second-step-inner-wrapper">
		<h2>Select parts that you would like to have on your website</h2>
		<div class="second-step-items-container">
			<div
				v-for="(item, index) in data"
				:key="index"
				class="item-wrapper"
				:class="{ selected: isSelected(item.id) }"
				@click="toggleSelection(item.id)"
			>
				<div class="imgwrap">
					<inline-svg :src="item.imgUrl" alt="icon" />
				</div>
				<h3>{{ item.title }}</h3>
				<div class="tooltip" :class="getTooltipClass(index)">
					{{ item.text }}
				</div>
				<div class="checkmark">
					<inline-svg class="checkmark-img" :src="checkmark" alt="icon" />
				</div>
			</div>
		</div>
		<div class="btn-wrapper">
			<router-link class="btn" :to="{ name: 'QuoteStart' }">Back</router-link>
			<router-link class="btn btn-next" :to="{ name: 'QuoteDate' }"
				>Next</router-link
			>
		</div>
	</div>
</template>

<script setup lang="ts">
import animateElementsOnScroll from "@/js/intersection-observer.v2.js";
import staticReviews from "@/assets/icons/quote/quote-static-reviews.svg";
import home from "@/assets/icons/quote/quote-home.svg";
import contact from "@/assets/icons/quote/quote-contact.svg";
import about from "@/assets/icons/quote/quote-about.svg";
import gallery from "@/assets/icons/quote/quote-gallery.svg";
import blog from "@/assets/icons/quote/quote-blog.svg";
import shop from "@/assets/icons/quote/quote-shop.svg";
import comments from "@/assets/icons/quote/quote-comments.svg";
import reviews from "@/assets/icons/quote/quote-reviews.svg";
import admin from "@/assets/icons/quote/quote-admin.svg";
import users from "@/assets/icons/quote/quote-users.svg";
import usersPanel from "@/assets/icons/quote/quote-users-panel.svg";
import checkmark from "@/assets/icons/quote/checkmark.svg";
import { useDefaultStore } from "@/store";

interface IDataItem {
	id: number;
	title: string;
	imgUrl: string;
	text: string;
	priceImpact: number;
}

const store = useDefaultStore();

const data: IDataItem[] = [
	{
		id: 1,
		title: "Landing page",
		imgUrl: home,
		text: "The first page the visitors land on when they go to your website.",
		priceImpact: 1,
	},
	{
		id: 2,
		title: "Contact page",
		imgUrl: contact,
		text: "A way for visitors to contact you, either through contact information or a form.",
		priceImpact: 1,
	},
	{
		id: 3,
		title: "About page",
		imgUrl: about,
		text: "A page with an overview of your brand and the services you offer.",
		priceImpact: 1,
	},
	{
		id: 4,
		title: "Gallery",
		imgUrl: gallery,
		text: "A place to show off your brand and services through images.",
		priceImpact: 1,
	},
	{
		id: 5,
		title: "Blog",
		imgUrl: blog,
		text: "A place to share stories and ideas with your users through periodical articles.",
		priceImpact: 1,
	},
	{
		id: 6,
		title: "E-commerce/Shop",
		imgUrl: shop,
		text: "If you are selling something, this would be the interface users would go through.",
		priceImpact: 0,
	},
	{
		id: 7,
		title: "Comment section",
		imgUrl: comments,
		text: "A section on one or multiple pages that allows visitors to leave comments, similar to social networks.",
		priceImpact: 1,
	},
	{
		id: 8,
		title: "Live reviews",
		imgUrl: reviews,
		text: "A section on one or multiple pages that allows users to leave reviews of your services in real time.",
		priceImpact: 2,
	},
	{
		id: 9,
		title: "Static reviews",
		imgUrl: staticReviews,
		text: "A section on one of multiple pages that allows you to post preselected reviews of your services.",
		priceImpact: 1,
	},
	{
		id: 10,
		title: "Admin panel",
		imgUrl: admin,
		text: "An interface that provides you with control over your whole platform and userbase.",
		priceImpact: 1,
	},
	{
		id: 11,
		title: "Users",
		imgUrl: users,
		text: "Allows your users to create a personal profile with a username and password.",
		priceImpact: 1,
	},
	{
		id: 12,
		title: "Users panel",
		imgUrl: usersPanel,
		text: "Allows your users to have control over their profiles and interactions with your website.",
		priceImpact: 2,
	},
];

const calculateTotalPriceImpact = () => {
	return data
		.filter((item) => store.quoteFeatures.includes(item.id))
		.reduce((acc, item) => acc + item.priceImpact, 0);
};

const toggleSelection = (id: number) => {
	const index = store.quoteFeatures.indexOf(id);
	if (index === -1) {
		store.quoteFeatures.push(id);
	} else {
		store.quoteFeatures.splice(index, 1);
	}

	store.priceImpactFeatures = calculateTotalPriceImpact();
	store.priceImpact = priceImpact();
};

const priceImpact = () => {
	if (store.quoteFeatures.includes(6)) {
		if (store.priceImpactFeatures <= 5) {
			return "medium";
		} else {
			return "high";
		}
	} else {
		if (store.priceImpactFeatures <= 5) {
			return "low";
		} else if (
			store.priceImpactFeatures > 5 &&
			store.priceImpactFeatures <= 10
		) {
			return "medium";
		} else {
			return "high";
		}
	}
};

const isSelected = (id: number) => store.quoteFeatures.includes(id);

const windowWidth = ref(window.innerWidth);

const getTooltipClass = (index: number) => {
	if (windowWidth.value <= 1450) {
		return {
			"tooltip-left": index % 3 === 0,
			"tooltip-right": index % 3 !== 0,
		};
	} else {
		return {
			"tooltip-left": index % 2 === 0,
			"tooltip-right": index % 2 !== 0,
		};
	}
};

const handleResize = () => {
	windowWidth.value = window.innerWidth;
};

onMounted(() => {
	window.addEventListener("resize", handleResize);
	animateElementsOnScroll(
		".second-step-inner-wrapper",
		"slideFromBot 1s 0s forwards",
		" h2 , .second-step-items-container, .btn-wrapper",
	);
});

onUnmounted(() => {
	window.removeEventListener("resize", handleResize);
});
</script>

<style lang="scss" scoped>
.second-step-inner-wrapper {
	max-width: 1400px;
	margin: 0 auto;

	h2 {
		font-style: normal;
		font-weight: 600;
		max-width: 800px;
		margin: 0 auto 48px;
		text-align: center;
		font-size: 32px;
		line-height: 40px;
	}

	.second-step-items-container {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		position: relative;

		.item-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			min-height: 100%;
			box-sizing: border-box;
			width: 50%;
			max-width: 98%;
			padding: 48px;
			z-index: 2;
			cursor: pointer;
			position: relative;

			.tooltip {
				position: absolute;
				background-color: $section-dark-text;
				box-shadow: 0 1px 3px 0 $second-app-color;
				color: #fff;
				padding: 18px;
				border-radius: 4px;
				font-size: 16px;
				opacity: 0;
				visibility: hidden;
				transition: opacity 0.3s ease;
				width: 200px;
				border-top: 6px solid $second-app-color;
			}

			.tooltip-left {
				right: -20%;
				top: 50%;
			}

			.tooltip-right {
				left: -20%;
				top: 50%;
			}

			.imgwrap {
				margin-bottom: 2rem;
				height: 50px;
				aspect-ratio: 1 / 1;
				text-align: center;

				:deep() {
					svg {
						height: 100%;
						path {
							fill: $second-app-color;
						}
					}
				}
			}

			h3 {
				font-size: 16px;
				line-height: 20px;
				text-align: center;
				height: 40px;
			}

			&:hover {
				.imgwrap {
					:deep() {
						svg {
							transform: scale(1.2);
							transition: transform 0.3s ease-in-out;
						}
					}
				}
			}

			.checkmark {
				height: 40%;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				display: none;

				.checkmark-img {
					height: 100%;
					width: 100%;
				}
			}

			&.selected {
				.imgwrap {
					:deep() {
						svg {
							transition: all 0.2s ease;
							transform: scale(1.2);
							opacity: 0.4;
						}
					}
				}

				h3 {
					opacity: 0.4;
				}

				.checkmark {
					display: block;
				}
			}
		}
	}

	.btn-wrapper {
		display: flex;
		justify-content: center;
		.btn {
			margin: 48px 0;
			width: 200px;

			&.btn-next {
				background: $app-color;
				margin-left: 1rem;
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.second-step-inner-wrapper {
		h2 {
			font-size: 54px;
			line-height: 58px;
		}

		.second-step-items-container {
			.item-wrapper {
				width: 350px;

				h3 {
					font-size: 24px;
					line-height: 28px;
					height: unset;
				}

				&:hover {
					.tooltip {
						opacity: 1;
						visibility: visible;
					}
				}
			}

			.glow-img {
				display: block;
			}
		}

		.btn-wrapper {
			.btn {
				&.btn-next {
					margin-left: 2rem;
				}
			}
		}
	}
}
</style>
