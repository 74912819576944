<template>
	<div class="quote-page-outer-wrapper">
		<div class="quote-progress">
			<span
				v-for="item in steps"
				:key="item.id"
				class="spinner-image"
				:class="{ inactive: item.id < currentStepId }"
				:title="item.titleAttr"
			>
				<img
					class="a a-back"
					:draggable="false"
					alt="img"
					src="@/assets/icons/spinner-back.svg"
				/>
				<img
					v-if="item.id === currentStepId"
					class="a a1"
					draggable="false"
					src="@/assets/icons/spinner-team.svg"
				/>
				<img
					v-if="item.id === currentStepId"
					class="a a2"
					draggable="false"
					src="@/assets/icons/spinner-team.svg"
				/>
				<img
					v-if="item.id === currentStepId"
					class="a a3"
					draggable="false"
					src="@/assets/icons/spinner-team.svg"
				/>
				<img
					class="b"
					draggable="false"
					src="@/assets/icons/spinner-inner.svg"
				/>
				<div class="center-text">
					<div class="text-up">{{ item.id }}</div>
				</div>
			</span>
			<div class="progress-bg"></div>
		</div>

		<div class="price-impact-outer-wrapper">
			<div class="price-impact-wrapper">
				<div
					class="price-impact-low"
					:class="{ active: store.priceImpact === 'low' }"
				></div>
				<div
					class="price-impact-medium"
					:class="{ active: store.priceImpact === 'medium' }"
				></div>
				<div
					class="price-impact-high"
					:class="{ active: store.priceImpact === 'high' }"
				></div>
			</div>
			<div class="price-impact-label">
				<img
					src="@/assets/icons/quote/sack-dollar-solid.svg"
					alt="dollar"
					class="dollar-icon"
				/>
				Price impact
			</div>
			<div class="price-impact-tooltip">
				<p class="price-impact-tooltip-text">
					Price impact determins how much the items that you selected impact the
					overall price of the final product. This is a general tip and should
					not be taken literally, as your price may vary depending on your exact
					neads.
				</p>
				<p class="price-impact-tooltip-green">
					<img
						class="price-impact-tooltip-icon"
						alt="circle-icon"
						src="@/assets/icons/quote/circle-solid-green.svg"
					/>
					the impact is relatively low, and your price should remain low
				</p>
				<p class="price-impact-tooltip-yellow">
					<img
						class="price-impact-tooltip-icon"
						:draggable="false"
						alt="circle-icon"
						src="@/assets/icons/quote/circle-solid-yellow.svg"
					/>
					the impact is starting to increase, but is still normal
				</p>
				<p class="price-impact-tooltip-red">
					<img
						class="price-impact-tooltip-icon"
						:draggable="false"
						alt="circle-icon"
						src="@/assets/icons/quote/circle-solid-red.svg"
					/>
					the impact is higher than average
				</p>
			</div>
		</div>
		<div class="quote-page-inner-wrapper">
			<router-view />
			<inline-svg class="glow-img" :src="glowImg"></inline-svg>
		</div>
	</div>
</template>

<script setup>
import glowImg from "@/assets/images/upgraded/glow.svg";
import { useDefaultStore } from "@/store";

const store = useDefaultStore();
const route = useRoute();

const steps = [
	{
		id: 1,
		routeName: "QuoteStart",
		titleAttr: "Get your free quote today",
	},
	{
		id: 2,
		routeName: "QuoteFeatures",
		titleAttr: "Select parts that you would like to have on your website",
	},
	{
		id: 3,
		routeName: "QuoteDate",
		titleAttr: "Choose a tentative date for the website to be completed",
	},
	{
		id: 4,
		routeName: "QuoteDesign",
		titleAttr: "Choose a design path for your website",
	},
	{
		id: 5,
		routeName: "QuoteContact",
		titleAttr: "Tell us who we should contact with price information",
	},
	{
		id: 6,
		routeName: "QuoteDone",
		titleAttr: "You're done!",
	},
];

const currentStep = computed(
	() => steps.find((step) => step.routeName === route.name) || steps[0],
);
const currentStepId = computed(() => currentStep.value.id);

const handleScroll = () => {
	const priceImpacWwrapper = document.querySelector(
		".price-impact-outer-wrapper",
	);
	if (window.scrollY >= 120) {
		priceImpacWwrapper.classList.add("price-impact-outer-wrapper-solid");
	} else {
		priceImpacWwrapper.classList.remove("price-impact-outer-wrapper-solid");
	}
};

onUnmounted(() => {
	window.removeEventListener("scroll", handleScroll);
	store.resetForm();
});

onMounted(() => {
	window.addEventListener("scroll", handleScroll);
	store.quoteReferrer = document.referrer;
});
</script>

<style lang="scss" scoped>
.quote-page-outer-wrapper {
	padding-top: calc($top-page-nav-height + 40px);
	position: relative;

	.quote-progress {
		height: 40px;
		width: 100%;
		display: flex;
		justify-content: space-around;
		position: relative;
		max-width: 1500px;
		margin: 0 auto;
		overflow: hidden;

		.progress-bg {
			height: 50%;
			width: 98vw;
			box-sizing: border-box;
			position: absolute;
			top: 0;
			left: 50%;
			transform: translate(-50%);
			border-bottom: 1px solid rgba(34, 40, 52);
		}

		.spinner-image {
			$size: 40px;

			width: $size;
			min-width: $size;
			height: $size;
			user-select: none;
			display: flex;
			justify-content: center;
			align-items: center;
			z-index: 2;

			&.inactive {
				opacity: 0.2;
			}

			img.a {
				position: absolute;
				animation-name: spin;
				animation-duration: 5000ms;
				animation-iteration-count: infinite;
				animation-timing-function: linear;
				width: 100%;
				height: 100%;

				&.a1 {
					animation-duration: 5000ms;
				}

				&.a2 {
					animation-duration: 4000ms;
					animation-direction: reverse;
				}

				&.a3 {
					animation-duration: 6000ms;
					animation-direction: reverse;
				}
			}

			img.b {
				$pos: 4px;

				position: absolute;
				left: $pos;
				top: $pos;
				width: 80%;
				height: 80%;
			}

			.center-text {
				position: absolute;
				top: 48%;
				left: 50%;
				transform: translate(-50%, -50%);
				color: #fff;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				display: flex;
				justify-content: center;
				align-items: center;

				.text-up {
					font-size: 20px;
					font-weight: bold;
				}
			}
		}

		@for $i from 1 through 8 {
			&:nth-child(#{$i}) {
				.spinner-image {
					img {
						animation-delay: 300ms * $i;
					}
				}
			}
		}
	}

	.price-impact-outer-wrapper {
		top: $top-page-nav-height;
		background-color: transparent;
		transition: background-color 0.5s ease;
		padding-bottom: 12px;
		position: sticky;
		z-index: 3;

		&.price-impact-outer-wrapper-solid {
			background-color: rgb(13, 16, 21);
			z-index: 3;
		}

		.price-impact-tooltip {
			position: absolute;
			background-color: $section-dark-text;
			box-shadow: 0 1px 3px 0 $second-app-color;
			color: #fff;
			padding: 18px;
			border-radius: 4px;
			font-size: 16px;
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.3s ease;
			width: 400px;
			max-width: 90%;
			border-top: 6px solid $second-app-color;
			right: 50%;
			transform: translateX(50%);
			top: 60px;
			z-index: 3;

			.price-impact-tooltip-text {
				margin-bottom: 12px;
			}

			.price-impact-tooltip-green,
			.price-impact-tooltip-yellow,
			.price-impact-tooltip-red {
				.price-impact-tooltip-icon {
					height: 18px;
					vertical-align: middle;
				}
			}
		}

		.price-impact-label {
			font-size: 12px;
			font-weight: 700;
			margin: 8px 1.6rem 0;
			opacity: 0.6;
			user-select: none;

			.dollar-icon {
				margin-right: 4px;
				height: 10px;
			}
		}
		.price-impact-wrapper {
			display: flex;
			justify-content: space-around;
			align-items: center;
			padding: 20px 1rem 0;
			box-sizing: border-box;

			.price-impact-low,
			.price-impact-medium,
			.price-impact-high {
				height: 12px;
				border-radius: 50px;
				box-sizing: border-box;
				border: 1px solid $section-color;
				position: relative;
				width: 30%;
				transition: background-color 0.6s ease;
			}

			.price-impact-low {
				&.active {
					background-color: $app-color;
				}
			}

			.price-impact-medium {
				&.active {
					background-color: $warning-color;
				}
			}

			.price-impact-high {
				&.active {
					background-color: $error-color;
				}
			}
		}

		&:hover {
			.price-impact-tooltip {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.quote-page-inner-wrapper {
		padding: 8px 20px 0;
		min-height: 100vh;
		position: relative;

		.glow-img {
			position: absolute;
			margin: auto;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			max-width: 100%;
			z-index: -1;
		}
	}
}

@media screen and (min-width: 768px) {
	.quote-page-outer-wrapper {
		.quote-progress {
			height: 60px;
			overflow: unset;

			.spinner-image {
				$size: 60px;

				width: $size;
				min-width: $size;
				height: $size;

				img.b {
					$pos: 6px;

					left: $pos;
					top: $pos;
				}

				.center-text {
					.text-up {
						font-size: 25px;
					}
				}
			}

			@for $i from 1 through 8 {
				&:nth-child(#{$i}) {
					.spinner-image {
						img {
							animation-delay: 300ms * $i;
						}
					}
				}
			}
		}

		.price-impact-outer-wrapper {
			position: relative;
			top: unset;
			padding-bottom: unset;

			.price-impact-label {
				margin: 16px auto 0;
				max-width: 1340px;
				padding: 0 42px;
				font-size: 25px;

				.dollar-icon {
					margin-right: 8px;
					height: 24px;
				}
			}

			.price-impact-wrapper {
				padding: 0 20px;
				margin: 38px auto 0;
				max-width: 1340px;
			}

			.price-impact-tooltip {
				top: 48px;
				right: 20%;
				transform: unset;
			}
		}

		.quote-page-inner-wrapper {
			padding: 38px 20px 0;
		}
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@keyframes spin-alt {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}
</style>
